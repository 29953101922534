import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export const useStyles = makeStyles({
  container: {
    marginTop: 150,
  },
  subheader: {
    marginTop: 40,
  },
  body: {
    marginTop: 15,
  },
});

const Privacy: React.FC = () => {
  const classes = useStyles();

  return (
    <Layout isHeaderSticky={false}>
      <SEO title="Privacy Policy" />
      <Grid container spacing={3} className={classes.container}>
        <Grid item md={2} sm={1} />
        <Grid item xs={10} md={8}>
          <Typography variant="h3">Privacy Policy</Typography>
          <Typography variant="h5" className={classes.subheader}>
            1. ACCEPTANCE OF PRIVACY POLICY
          </Typography>
          <Typography variant="body1" className={classes.body}>
            Protecting your privacy is important to us. We collect and manage
            user data according to the following Privacy Policy. This document
            is part of our Terms of Service, and by using our website or mobile
            applications (the “Services”), you agree to the terms of this
            Privacy Policy and the Terms of Service. Please read the Terms of
            Service in their entirety, which are located at &nbsp;
            <Link to="/terms">https://www.findingfocus.app/terms</Link>
          </Typography>

          <Typography variant="h5" className={classes.subheader}>
            2. DATA COLLECTED
          </Typography>
          <Typography variant="body1" className={classes.body}>
            We only collect data necessary to fulfill the Services outlined in
            our Terms of Service. Data include all Personally Identifiable
            Information (PII) and other non-public information. Data include,
            but are not limited to, user data, metadata, and user-generated
            content.
            <br />
            <br />
            We collect anonymous data from every user of the Website to monitor
            traffic and fix bugs. For example, we collect information like web
            requests, the data sent in response to such requests, the Internet
            Protocol address, the browser type, the browser language, and a
            timestamp for the request.
            <br />
            <br />
            We ask you to provide your name and email address in order to create
            your account. This information is stored securely and never shared
            or sold. You are able to view and change the information associated
            with your profile.
            <br />
            <br />
            We also use local storage technology that provides similar
            functionality to cookies, but its contents are not transmitted
            across the Internet. Your browser settings allow you to control how
            local storage works on a global or website specific manner.
            Disabling local storage will interfere with some features of the
            Services.
            <br />
            <br />
            We collect data about your use of the Services, including the
            frequency and times at which you access them. As you complete
            courses, we also record your answers to multiple choice and free
            response questions.
            <br />
            <br />
            You must be at least 13 years old to use the Services. We do not
            knowingly collect information from children under 13.
          </Typography>

          <Typography variant="h5" className={classes.subheader}>
            3. DATA SECURITY
          </Typography>
          <Typography variant="body1" className={classes.body}>
            We store and process data in accordance with industry best
            practices. This includes appropriate administrative, physical, and
            technical safeguards to secure data from unauthorized access,
            disclosure, and use. We conduct periodic risk assessments and
            address any identified security vulnerabilities in a timely manner.
            We also have a written incident response plan to include prompt
            notification in the event of a security or privacy incident, as well
            as best practices for responding to a breach of PII. The incident
            response plan is available upon request.
            <br />
            <br />
            Please be aware, however, that no method of transmitting information
            over the Internet or storing information is completely secure.
            Accordingly, we cannot guarantee the absolute security of any
            information.
          </Typography>

          <Typography variant="h5" className={classes.subheader}>
            4. USE OF THE DATA
          </Typography>
          <Typography variant="body1" className={classes.body}>
            Data will only be used to provide the Services. Your data will never
            be sold. Student data will never be used to advertise or market to
            students or their parents.
            <br />
            <br />
            We use your personal information to provide you the Services and to
            communicate with you about the Services. We use local storage to
            store your preferences and make your user experience more
            consistent. We may send you emails about your use of the Services.
            If you do not want to receive email from us, please opt out of
            receiving emails at the bottom of any email you receive from us.
            <br />
            <br />
            We also use de-identified data about your use of the Services in
            order to identify ways to improve the Services. De‐identified data
            will have all direct and indirect personal identifiers removed. This
            includes, but is not limited to, name, email, and location
            information. We will not attempt to re‐identify de-identified Data.
          </Typography>

          <Typography variant="h5" className={classes.subheader}>
            5. SHARING OF DATA
          </Typography>
          <Typography variant="body1" className={classes.body}>
            With the exceptions noted below for minors and students
            participating through their school/district, we do not share
            personal information without your consent except as required by law.
            <br />
            <br />
            If you are a student who signed up for a course using an invite code
            provided by your school/district, certain information about your use
            of the Services is automatically shared with your teacher and/or
            school administrator, including your name and your progress through
            the course. Other personally identifying information and your
            answers to questions within the course are not automatically shared.
            However, the data we collect from students is the property of their
            schools/districts. Any data we hold will be made available to your
            school/district upon their request.
            <br />
            <br />
            If you are a minor, your guardian can request access to your data.
            If you are participating through your school/district, this request
            must be made through the school/district. Otherwise, your guardian
            can make this request by contacting cmhp@psych.ucsb.edu
            <br />
            <br />
            Aggregated, anonymized data is periodically transmitted to external
            services to help us improve the Services.
            <br />
            <br />
            If the Services are ever acquired by a different business entity,
            user information would be transferred to that entity. You
            acknowledge that such transfers may occur, and that any acquirer of
            the Services may continue to use your personal information as set
            forth in this policy.
          </Typography>

          <Typography variant="h5" className={classes.subheader}>
            6. DELETING DATA
          </Typography>
          <Typography variant="body1" className={classes.body}>
            You may request to have your account and data deleted, but how you
            make that request depends on whether or not you are participating
            through your school.
            <br />
            <br />
            If you are participating through your school: Requests to delete
            your account and data must be made through the school/district.
            Schools/districts may also elect to delete student accounts and
            data.
            <br />
            <br />
            If you are not participating through your school: You may request to
            have your account and data deleted directly. Parents of minors may
            also request that their child’s account and data be deleted. Please
            send requests to cmhp@psych.ucsb.edu
          </Typography>

          <Typography variant="h5" className={classes.subheader}>
            7. CHANGES TO THE PRIVACY POLICY
          </Typography>
          <Typography variant="body1" className={classes.body}>
            We may change this Privacy Policy periodically. If we do, we will
            email you with notice of the changes. At the bottom of the Privacy
            Policy, we will also indicate the date these terms were last
            revised. Use of information we collect now is subject to the Privacy
            Policy in effect at the time such information is used. You are bound
            by any changes to the Privacy Policy when you use the Services after
            such changes have been posted. If you have questions about this
            privacy policy, please contact cmhp@psych.ucsb.edu.
          </Typography>
          <Typography variant="body1" className={classes.body}>
            <br />
            Last revised on 3/7/2019
          </Typography>
        </Grid>
        <Grid item md={2} sm={1} />
      </Grid>
    </Layout>
  );
};

export default Privacy;
